.navbar {
    border-bottom: 1px solid $violet;
    padding: 0 1rem !important;
    
    a {
        
            line-height: 4rem;
            font-size: 1.2rem;
    }
    
    .active {
        a {
            background-color: $violet;
        }
    }
}