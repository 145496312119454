.headerImgWrapper {
    @include background-fixed("../images/header.jpg");
    @media (min-width: 576px) {
        min-height: 300px;
    }

    @media (min-width: 768px) {
        min-height: 400px;
    }

    @media (min-width: 992px) {
        min-height: 500px;
    }

    @media (min-width: 1200px) {
        min-height: 600px;
    }
    
    .news {
        padding-top: 50px;
    }
}