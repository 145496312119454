.intro {
    padding-top: 3rem;
    padding-bottom: 2rem;
    border-top: 3px solid $violet;
    border-bottom: 3px solid $violet;
    
    .fas {
        margin-bottom: 2rem;
        color: $violet;
    }
}