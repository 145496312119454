footer {
    
    background-color: $violet;
    color: $white;
    
    a {
        color: $white;
        text-decoration: underline;
    }
    
    
    .container {
        padding-bottom: 40px;
        padding-top: 40px;
    }
    
    
    h1,h2,h3,h4,label {
        color: $white;
    }

    input {
        border-top: 0px !important;
        border-right: 0px !important;
        border-bottom: 1px solid $white !important;
        border-left: 0px !important;
    }

    textarea {
        border-top: 0px !important;
        border-right: 0px !important;
        border-bottom: 1px solid $white !important;
        border-left: 1px;
    }

    .ba-btn-submit {
        border: 0px !important;
    }

    h2 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .bigger {
        font-size: 1.6rem;
    }

    .impressum {
        padding-top: 20px;
        padding-bottom: 20px;
        
        .copyright {
            font-size: .8rem;
            text-align: right;
        }
        
        a {
            color: $white;
            text-decoration: underline;
        }

        ul {
            display: inline;

            li {
                display: inline;
                .nav-link {
                    display: inline;
                    color: $white;
                    padding-right: 30px;
                }
            }
        }

    }

}