@import "mixins/background";
@import "default/header";
@import "default/navBar";
@import "default/headerImg";
@import "default/intro";
@import "default/futured";
@import "default/footer";
@import "default/blog";
@import "default/baForm";
@import "default/kontakt";

.img-right {
    float: right;
    margin-left: 40px;
}

.img-left {
    float: left;
    margin-right: 40px;
}