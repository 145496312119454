.logo-container {
    text-align: center;
    padding-top: 40px;
    
    img {
        max-width: 200px;
    }
}

.subsite {
    border-bottom: 3px solid $violet;
    padding-bottom: 40px;
}