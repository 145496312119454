.futured {
    padding-top: 40px;
    padding-bottom: 40px;
    
    img {
        max-width: 100%; 
    }



    @media (max-width: 577px) {
        .img-right {
            float: none;
            margin-left: 0px !important;
        }

        .img-left {
            float: none;
            margin-right: 0px !important;
        }
    }
}